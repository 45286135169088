import React from 'react'
import { CookiesProvider } from 'react-cookie'
// import MetaTags from './meta-tags'

const defaultContextValue = {
  data: {
    meta: {},
    hidePresentingSponsor: false,
    category: null,
    // openMapOnMount: false,
    mapOpen: false,
  },
  set: () => {},
  toggleMap: () => {},
}

const LayoutContext = React.createContext(defaultContextValue)

class ContextProviderComponent extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.toggleMap = this.toggleMap.bind(this)

    this.state = {
      ...defaultContextValue,
      set: this.setData,
      toggleMap: this.toggleMap,
    }
  }

  setData(newData) {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  toggleMap = (forceClose = false) => {
    this.setData({ mapOpen: forceClose ? false : !this.state.data.mapOpen })
  }

  render() {
    return (
      <LayoutContext.Provider value={this.state}>
        <CookiesProvider>{this.props.children}</CookiesProvider>
      </LayoutContext.Provider>
    )
  }
}

export { LayoutContext as default, ContextProviderComponent }
