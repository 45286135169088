import { useStaticQuery, StaticQuery, graphql, Link } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import React, { useState, useContext } from 'react'
import LayoutContext from './context'

const META_FIELDS = [
  'description',
  'keywords',
  'robots',
  'og:title',
  'og:description',
  'og:image',
  'og:url',
  'og:site_name',
  'twitter:title',
  'twitter:description',
  'twitter:image',
  'twitter:card',
]

const MetaTags = () => {
  let {
    data: { meta },
  } = useContext(LayoutContext)

  meta = meta || {}

  const defaultData = useStaticQuery(
    graphql`
      query metaQuery {
        contentfulMetaTags(pagePath: { eq: "/default" }) {
          title
          image {
            gatsbyImageData(width: 1200, height: 630)
          }
          url
          description {
            description
          }
        }
        contentfulGptAdUnit(slug: { eq: "presenting-sponsor" }) {
          slot
          width
          height
          fullColor
          lede
        }
      }
    `
  )

  const { includeDefaults = true, ...other } = meta
  if (includeDefaults) {
    meta = { ...defaultData.contentfulMetaTags, ...other }
  }

  // flatten image/description Contenful format
  meta.description =
    meta.description && meta.description.description
      ? meta.description.description
      : meta.description

  meta.image =
    meta.image && meta.image.gatsbyImageData ? getSrc(meta.image) : meta.image

  const helmet = () => {
    return {
      meta: tags(),
      title: value('title'),
    }
  }

  const tags = (args) => {
    return META_FIELDS.map((t) => {
      return tagFor(t)
    }).filter((tg) => {
      return !!tg
    })
  }

  const tagFor = (field) => {
    // ie. m.tag('description') => { name: 'description', content: value('description')}
    if (META_FIELDS.includes(field)) {
      const content = value(field)
      return content ? { ...name(field), content: value(field) } : null
    }
    return null
  }

  const name = (field) => {
    const x = field.match(/^og:/) ? { property: field } : { name: field }
    return x
  }

  const value = (field) => {
    const f = field.replace(/^(og|twitter):/, '')
    return meta[f]
  }

  return <Helmet {...helmet()} />
}

export default MetaTags
