import React, { useRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import lottie from 'lottie-web'
import gsap from 'gsap'

import MapPin from './pin'

class MapTile extends React.Component {
  constructor(props) {
    super(props)

    const { category, autoplay = false } = this.props

    this.state = {
      category,
      autoplay,
    }
    this.animationRef = React.createRef()
    this.animation = null
    this.mouseEnter = this.mouseEnter.bind(this)
    this.mouseLeave = this.mouseLeave.bind(this)
  }

  componentDidMount() {
    if (this.state.category.lottieJSON) {
      this.animation = lottie.loadAnimation({
        container: this.animationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: this.state.autoplay,
        path: this.state.category.lottieJSON.file.url,
      })
    }

    // if (!this.state.autoplay)
    //   gsap.set(this.animationRef.current, {
    //     opacity: 0.7,
    //     transform: 'scale(0.95)',
    //   })
  }

  mouseEnter() {
    if (!this.state.autoplay) {
      if (this.animation) this.animation.play()

      // gsap.to(this.animationRef.current, {
      //   ease: 'back',
      //   duration: 0.1,
      //   opacity: 1,
      //   transform: 'scale(1)',
      // })
    }
  }

  mouseLeave() {
    if (!this.state.autoplay) {
      if (this.animation) this.animation.pause()

      // gsap.to(this.animationRef.current, {
      //   ease: 'back',
      //   duration: 0.1,
      //   opacity: 0.7,
      //   transform: 'scale(0.95)',
      // })
    }
  }

  render() {
    return (
      <div
        key={this.state.category.slug}
        className="map-tile"
        id={`category-map-${this.state.category.slug}`}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <div
          className="map-animation"
          style={{ width: '100%', height: '100%' }}
          ref={this.animationRef}
        >
          {this.state.category.lottieJSON ? null : (
            <GatsbyImage
              image={this.state.category.mapTilePlaceholder.gatsbyImageData}
              className="d-block w-100 h-100"
              alt=""
            />
          )}
        </div>
        <MapPin category={this.state.category} />
      </div>
    )
  }
}
export default MapTile
