import React from 'react'
import get from 'lodash/get'
import { StaticQuery, Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

export default () => (
  <StaticQuery
    query={graphql`
      query SchoolListQuery {
        allContentfulSchool(
          filter: {
            contentful_id: { ne: "5qXVQWEerwQDygLHj9QNAo" }
            partner: { eq: true }
          }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              name
              slug
              url
              logoWhite {
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                  url
                }
              }
              copy {
                raw
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const schools = get(data, 'allContentfulSchool.edges')
      return schools.length > 0 ? (
        <Container as="section" fluid="lg" className="bg-black">
          <Row className="align-items-start">
            <Col lg="3" className="pb-3 pb-lg-0">
              <h3 className="hu h2 text-yellow">HBCU Partners</h3>
            </Col>
            <Col>
              <div className="schools-list">
                {schools.map(({ node }) => {
                  return node.copy ? (
                    <Link
                      key={node.slug}
                      to={`/hbcu-partners/${node.slug}`}
                      className="school-logo-link"
                    >
                      <img src={node.logoWhite.file.url} alt={node.name} />
                    </Link>
                  ) : (
                    <a
                      key={node.slug}
                      href={node.url}
                      className="school-logo-link"
                      target="_blank"
                    >
                      <img src={node.logoWhite.file.url} alt={node.name} />
                    </a>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      ) : null
    }}
  />
)
