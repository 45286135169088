import React from 'react'
import { Link } from 'gatsby'

class CategoryNavItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hovered: false,
    }
    this.handleHover = this.handleHover.bind(this)
  }

  handleHover() {
    this.setState((state, props) => {
      return { hovered: !state.hovered }
    })
  }

  render() {
    const { category } = this.props
    return (
      <Link
        to={`/categories/${category.slug}`}
        className={`category-link ${this.props.active ? 'active' : ''}`}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
        data-sal="slide-up"
        data-sal-delay={(this.props.index + 1) * 100}
        data-sal-duration={300}
      >
        <img
          src={
            this.state.hovered || this.props.active
              ? category.icon.file.url
              : category.iconBW.file.url
          }
          alt={category.name}
          width={category.icon.file.details.image.width * 0.7}
          height={category.icon.file.details.image.height * 0.7}
        />
        <div
          className="mobile-category-link"
          style={{
            backgroundColor: `#${category.colorPalette.primaryColor}`,
          }}
        >
          <div
            className="mobile-icon"
            style={{
              backgroundColor: `#${category.colorPalette.secondaryColor}`,
            }}
          >
            <img
              src={category.icon.file.url}
              alt={category.name}
              className="w-icon"
            />
          </div>
          <span className="category-name">{category.name}</span>
        </div>
      </Link>
    )
  }
}
export default CategoryNavItem
