import React from 'react'
import MapTile from '../map/tile'
import gsap from 'gsap'
import LayoutContext from '../../components/layout/context'

class Map extends React.Component {
  static contextType = LayoutContext

  constructor(props, context) {
    super(props)

    const { categories } = this.props

    this.state = {
      categories,
    }

    this.mapRef = React.createRef()
    this.toggleMap = this.toggleMap.bind(this)
    this.resizeMap = this.resizeMap.bind(this)
  }

  resizeMap() {
    // map is 2x2, so always square
    // get window.width & window.height
    // ? portrait : landscape
    const mapW =
      typeof window !== 'undefined' &&
      window.innerHeight > window.innerWidth &&
      window.innerWidth > 578
        ? '100vh' // tablet portrait
        : '100vw'
    // scale accordingly
    this.mapRef.current.style.width = mapW
    this.mapRef.current.style.height = mapW
  }

  toggleMap(forceClose = false) {
    const { mapOpen } = this.context.data
    if (!forceClose && mapOpen) {
      gsap.to('main', {
        opacity: 0,
        display: 'none',
        duration: 0.3,
        ease: 'power4.out',
      })
      // gsap.to('.map-container', {
      //   filter: 'blur(0px)',
      //   duration: 0.2,
      //   ease: 'out',
      // })
    } else {
      gsap.to('main', {
        opacity: 1,
        display: 'block',
        duration: 0.3,
        ease: 'power4.out',
      })
      // gsap.to('.map-container', {
      //   filter: 'blur(4px)',
      //   duration: 0.2,
      //   ease: 'out',
      // })
    }
  }

  componentDidMount() {
    // size the map
    this.resizeMap()
    // add resize listener
    window.addEventListener('resize', this.resizeMap)
  }

  componentWillUnmount() {
    // remove resize listener
    window.removeEventListener('resize', this.resizeMap)
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('MAP: ', this.context.data.mapOpen)
    this.toggleMap()
  }

  render() {
    const { categories } = this.state
    const { mapOpen } = this.context.data
    return (
      <div className={`map-container ${mapOpen ? '' : 'closed'}`}>
        <div ref={this.mapRef} className="map-inner">
          {categories.map(({ node: category }) => {
            return <MapTile key={category.slug} category={category} />
          })}
        </div>
      </div>
    )
  }
}
export default Map
