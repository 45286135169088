import React from 'react'
import get from 'lodash/get'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Container, Navbar, Nav, Dropdown } from 'react-bootstrap'
import CategoryNavItem from '../../components/categories/nav-item'
// import Logo from './logo'
import moment from 'moment'

import LayoutContext from './context'

import logoImg from '../../../static/images/logos/logo-white.svg'
import GPTUnit from '../../components/misc/gpt-unit'
import Search from '../../components/misc/search'
import sponsoredLogo from '../../../static/images/logos/pg-lockup.svg'
import gsap from 'gsap'
import Map from './map'

class Navigation extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  navOpen() {
    // open animation
    const tl = gsap.timeline()

    tl.to('.main-nav-container', {
      duration: 0.5,
      y: '0%',
      ease: 'power2.out',
    })
    tl.from(
      '.nav-header',
      {
        duration: 0.2,
        x: '10px',
        opacity: 0,
        stagger: 0.1,
        ease: 'power2.out',
      },
      '-=0.2'
    )
    tl.from(
      '.category-link, .nav-link',
      {
        duration: 0.5,
        y: '20px',
        opacity: 0,
        stagger: 0.08,
        ease: 'power4.out',
      },
      '<'
    )
    tl.play()
  }

  navClose() {
    // close animation
    // const tl = gsap.timeline()
    gsap.to('.main-nav-container', {
      duration: 0.4,
      y: '-100%',
      ease: 'power2.out',
    })
    // tl.play()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.menuOpen != this.state.menuOpen) {
      if (this.state.menuOpen) {
        this.navOpen()
      } else {
        this.navClose()
      }
    } else if (
      (this.state.menuOpen || this.context.data.mapOpen) &&
      prevProps.location.pathname != this.props.location.pathname
    ) {
      // page changed
      console.log('!HEWRE I AM')
      this.setState({ menuOpen: false })
      this.context.toggleMap(true)
      // this.navClose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query navDropdownCategoryQuery {
            siteSearchIndex {
              index
            }
            contentfulGptAdUnit(slug: { eq: "presenting-sponsor" }) {
              slot
              width
              height
              fullColor
              lede
            }
            allContentfulEvent(
              filter: { contentful_id: { ne: "3FVs0RszkWF9ioi31DH8IU" } }
            ) {
              totalCount
            }
            allContentfulCategory(
              filter: { contentful_id: { ne: "5FkcKCGaTq2LURVvai82vP" } }
              sort: { fields: [position], order: ASC }
            ) {
              edges {
                node {
                  name
                  slug
                  colorPalette {
                    primaryColor
                    secondaryColor
                  }
                  icon {
                    file {
                      details {
                        image {
                          height
                          width
                        }
                      }
                      url
                    }
                  }
                  mapTilePlaceholder {
                    gatsbyImageData(width: 1200, height: 1200)
                  }
                  article {
                    publishDate
                  }
                  lottieJSON {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const categories = get(data, 'allContentfulCategory.edges').filter(
            ({ node: category }) => {
              return !!category.mapTilePlaceholder
            }
          )
          // find most recent article
          categories.map(({ node: category }) => {
            if (category.article) {
              category.article.sort((a, b) => {
                // most recent first
                if (moment(a.publishDate).isBefore(moment(b.publishDate))) {
                  return 1
                } else {
                  return -1
                }
              })
              category.mostRecentArticle = category.article[0]
            }
          })
          // const categories = get(data, 'allContentfulCategory.edges')
          const searchIndex = get(data, 'siteSearchIndex.index')
          const presentingSponsor = get(data, 'contentfulGptAdUnit')

          return (
            <>
              <header className={this.state.menuOpen ? 'menu-open' : ''}>
                <div className="navbar">
                  <Link to={'/'} className="logo-img">
                    <img src={logoImg} alt="One Yard" />
                  </Link>

                  <button
                    onClick={() =>
                      this.setState((prevState) => ({
                        menuOpen: !prevState.menuOpen,
                      }))
                    }
                    className={`menu-button ${
                      this.state.menuOpen ? 'open' : ''
                    }`}
                  >
                    <span>Menu</span>
                  </button>
                </div>
                <button
                  onClick={() => {
                    this.context.toggleMap()
                  }}
                  className={`map-button ${
                    this.context.data.mapOpen ? 'open' : ''
                  }`}
                >
                  <span>Map</span>
                </button>

                <nav
                  className={`main-nav-container ${
                    this.state.menuOpen ? 'open' : ''
                  }`}
                >
                  <div className="main-nav-inner">
                    <h6 className="nav-header">Explore One Yard</h6>
                    <div className="nav-links">
                      {categories.map(({ node }) => {
                        return (
                          <div key={node.slug} className="category-link">
                            <Link to={`/categories/${node.slug}`}>
                              <img src={node.icon.file.url} alt={node.name} />
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                    <h6 className="nav-header">Find Us on Social Media</h6>
                    <div className="nav-links">
                      <a
                        href="https://www.instagram.com/1Yard.Co/"
                        target="_blank"
                        className="nav-link social-nav-link"
                        title="Follow One Yard on Instagram"
                      >
                        <i className="icon-instagram"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/1Yard.Co"
                        target="_blank"
                        className="nav-link social-nav-link"
                        title="Follow One Yard on Facebook"
                      >
                        <i className="icon-facebook"></i>
                      </a>
                      <a
                        href="https://twitter.com/1yardco"
                        target="_blank"
                        className="nav-link social-nav-link"
                        title="Follow One Yard on Twitter"
                      >
                        <i className="icon-twitter"></i>
                      </a>
                    </div>
                    <h6 className="nav-header">Search One Yard</h6>
                    <div className="nav-links">
                      <Search searchIndex={searchIndex} />
                    </div>
                  </div>
                </nav>
              </header>

              <Map location={this.props.location} categories={categories} />
            </>
          )
        }}
      />
    )
  }
}
export default Navigation
