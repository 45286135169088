import React from 'react'
import '../../scss/application.scss'
import { Helmet } from 'react-helmet'
import Navigation from './navigation'
import MapNavigation from './map'
import Footer from './footer'
import MetaTags from './meta-tags'
import { ContextProviderComponent } from './context'
import PageTransition from './page-transition'
import CategoryPanel from '../categories/panel'

export default ({ children, category, location }) => {
  return (
    <ContextProviderComponent>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        script={
          [
            // {
            //   type: 'text/javascript',
            //   src: '',
            // },
          ]
        }
        link={[
          {
            rel: 'stylesheet',
            type: 'text/css',
            href: 'https://use.typekit.net/wbn5jxp.css',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/images/webclip/apple-touch-icon.png',
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/images/webclip/favicon-32x32.png',
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/images/webclip/favicon-16x16.png',
          },
          {
            rel: 'mask-icon',
            href: '/images/webclip/safari-pinned-tab.svg',
          },
          {
            rel: 'manifest',
            href: '/manifest.json',
          },
        ]}
      >
        <script
          src="//cdn.embedly.com/widgets/platform.js"
          charset="UTF-8"
          async
          type="text/javascript"
        />
        <script src="//www.instagram.com/embed.js" type="text/javascript" />
        <script
          src="https://platform.twitter.com/widgets.js"
          type="text/javascript"
        />
        <script
          src="//w3.cdn.anvato.net/player/prod/v3/scripts/anvload.js"
          type="text/javascript"
        />
        <script
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          async
          type="text/javascript"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          window.googletag = window.googletag || { cmd: [] }
          `,
          }}
        />
      </Helmet>
      <MetaTags />
      <Navigation location={location} />
      <main>
        {/* <CategoryPanel /> */}
        <PageTransition location={location}>{children}</PageTransition>
        {/* <Footer /> */}
      </main>
    </ContextProviderComponent>
  )
}
