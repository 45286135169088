import React, { Component } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Index } from 'elasticlunr'
import { Link } from 'gatsby'

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
      searchOpen: false,
    }
    this.toggleSearch = this.toggleSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.search = this.search.bind(this)
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('keyup', this.handleKeyPress)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keyup', this.handleKeyPress)
    }
  }

  handleKeyPress(e) {
    if (this.state.searchOpen && e.keyCode === 27) {
      e.preventDefault()
      e.stopPropagation()
      this.toggleSearch()
    }
  }

  toggleSearch(e) {
    this.setState((prevState, props) => ({
      searchOpen: !prevState.searchOpen,
    }))
  }

  getOrCreateIndex() {
    // Create an elastic lunr index and hydrate with graphql query results
    return this.index ? this.index : Index.load(this.props.searchIndex)
  }

  search(evt) {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }

  render() {
    return (
      <>
        <a
          className="nav-link search-nav-link"
          title="Search One Yard"
          onClick={this.toggleSearch}
        >
          <i className="icon-search"></i>
        </a>

        <div
          className={`search-container ${
            this.state.searchOpen ? 'open' : 'closed'
          }`}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              this.toggleSearch()
            }
          }}
        >
          <div className="search-container-inner">
            <a className="close-search" onClick={this.toggleSearch}>
              <i className="icon-x"></i>
            </a>
            <h4 className="text-white hu h2 pb-3">Search One Yard</h4>
            <Form.Group controlId="name">
              <Form.Control
                placeholder="Search"
                name="q"
                autoComplete="off"
                value={this.state.query}
                onChange={this.search}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your name.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="search-results-container">
              {this.state.results.map((page) => (
                <Link to={page.path} key={page.id} className="search-result">
                  {page.thumbnail ? <img src={page.thumbnail} /> : null}
                  <div>
                    <span className="content-type">{page.contentType}</span>
                    <span className="title">{page.title}</span>
                    {page.subtitle ? (
                      <span className="subtitle">{page.subtitle}</span>
                    ) : null}
                    {page.category ? (
                      <span className="category">
                        <img
                          src={page.category.icon}
                          className="category-icon"
                        />
                        {page.category.name}
                      </span>
                    ) : null}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
}
