import React from 'react'
import { Link } from 'gatsby'

const Buffer = require('buffer/').Buffer;

class ArrowLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hovered: false,
    }
    // this.handleHover = this.handleHover.bind(this)
  }

  // handleHover() {
  //   this.setState((state, props) => {
  //     return { hovered: !state.hovered }
  //   })
  // }

  render() {
    let { as = Link, klass, color, arrowColor, children, ...other } = this.props

    const As = as
    arrowColor = arrowColor || color
    const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 20">
        <path fill="#${arrowColor}" d="M56.7,19.7l-1.4-1.4l7.3-7.3H0V9h62.6l-7.3-7.3l1.4-1.4l9,9C65.9,9.5,66,9.7,66,10s-0.1,0.5-0.3,0.7L56.7,19.7z"/>
      </svg>`

    const buff = Buffer.from(svgString, 'utf-8')
    const base64 = buff.toString('base64')
    return (
      <As
        style={{ color: `#${color}` }}
        className={`arrow-link ${klass || ''} ${
          this.state.hovered ? 'hovered' : ''
        }`}
        {...other}
        // onMouseEnter={this.handleHover}
        // onMouseLeave={this.handleHover}
      >
        <div className="arrow-link-img">
          <img src={`data:image/svg+xml;base64,${base64}`} />
        </div>{' '}
        {children}
      </As>
    )
  }
}
export default ArrowLink
