import React from 'react'
// import { Link } from 'gatsby'
import Signup from '../../components/signup'
import { Container, Row, Col } from 'react-bootstrap'
import SchoolsList from '../schools/list'

export default () => (
  <footer className="footer" role="navigation">
    <SchoolsList />
    <Signup />
    <hr className="flags" />

    <nav>
      <span>
        An{' '}
        <a href="https://urban1.com/" target="_blank">
          Urban One
        </a>{' '}
        Brand
      </span>
      <a href="https://urban1.com/privacy/" target="_blank">
        Privacy Policy
      </a>
      <a href="https://urban1.com/terms-of-service/" target="_blank">
        Terms of Service
      </a>
      <a href="#" id="ot-sdk-btn" className="ot-sdk-show-settings otSDKbtn">
        Do Not Sell my Personal Information
      </a>
    </nav>

    <p className="copyright">
      Copyright &copy; {new Date().getFullYear()}{' '}
      <a href="http://interactiveone.com/" target="_blank">
        Interactive One, LLC.
      </a>{' '}
      All Rights Reserved.{' '}
    </p>
  </footer>
)
