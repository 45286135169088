import React, { useEffect } from 'react'

class GPTUnit extends React.Component {
  constructor(props) {
    super(props)

    let {
      slot,
      width,
      height,
      lede,
      invert = false,
      fullColor = false,
      targeting = {},
    } = props

    if (!width.toString().match(/(px|\%)$/)) {
      width = `${width}px`
    }
    if (!height.toString().match(/(px|\%)$/)) {
      height = `${height}px`
    }
    this.state = {
      slot,
      width,
      height,
      lede,
      invert: fullColor ? false : invert,
      loading: true,
      targeting,
    }

    this.element = React.createRef()

    this.resizeAd = this.resizeAd.bind(this)
  }

  resizeAd(e) {
    if (e.detail.domID === this.state.domID) {
      if (!e.detail.isEmpty) {
        const adDIV = this.element.current.querySelector(
          'div[id^="google_ads"]'
        )
        if (adDIV) {
          const paddingTop = `${
            100 * (parseFloat(this.state.height) / parseFloat(this.state.width))
          }%`
          adDIV.style.width = this.state.width
          adDIV.style.paddingTop = paddingTop

          const iframe = this.element.current.querySelector(
            'iframe[id^="google_ads"]'
          )
          if (iframe) {
            const doc = iframe.contentDocument
            let styleSheet = doc.createElement('style')
            styleSheet.type = 'text/css'
            styleSheet.innerText = `.GoogleActiveViewElement img, img.img_ad { 
              width: 100%; 
              height: 100%; 
              filter: invert(${this.state.invert ? 100 : 0}%); 
            }`
            doc.head.appendChild(styleSheet)
          }
          // show the ad
          this.setState({ loading: false })
          // this.element.current.classList.remove('loading');
        }
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('adRendered', this.resizeAd)

      this.setState(
        {
          domID: `ad-unit-${
            Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000
          }`,
        },
        () => {
          window.googletag = window.googletag || { cmd: [] }
          googletag.cmd.push(() => {
            googletag
              .defineSlot(this.state.slot, [1, 1], this.state.domID)
              .addService(googletag.pubads())

            for (const [k, v] of Object.entries(this.state.targeting)) {
              googletag.pubads().setTargeting(k, v)
            }

            googletag.pubads().enableAsyncRendering()
            googletag.pubads().enableSingleRequest()
            googletag.enableServices()
          })
          googletag.cmd.push(() => {
            // inject resizing/recolor CSS
            window.resizeAdsListener =
              window.resizeAdsListener ||
              googletag.pubads().addEventListener('slotRenderEnded', (e) => {
                const evt = new CustomEvent('adRendered', {
                  detail: {
                    domID: e.slot.getSlotElementId(),
                    isEmpty: e.isEmpty,
                  },
                })
                window.dispatchEvent(evt)
              })

            googletag.display(this.state.domID)
          })
        }
      )
    }
  }

  componentWillUnmount() {
    googletag.destroySlots()
  }

  render() {
    return (
      <div
        className={`ione-gpt ${this.state.loading ? 'loading' : ''} ${
          this.props.className || ''
        }`}
      >
        {this.state.lede ? <span>{this.state.lede}</span> : ''}
        <div
          // className="ione-gpt loading"
          id={this.state.domID}
          ref={this.element}
        ></div>
      </div>
    )
  }
}
export default GPTUnit
