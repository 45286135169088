import React from 'react'
import ReactDOM from 'react-dom'
import { TransitionGroup, Transition } from 'react-transition-group'

export const transitionEvents = {
  page: 'page-transition', // general catch all
  enter: 'page-transition::enter',
  entering: 'page-transition::entering',
  entered: 'page-transition::entered',
  exit: 'page-transition::exit',
  exiting: 'page-transition::exiting',
  exited: 'page-transition::exited',
}

class PageTransition extends React.Component {
  constructor(props) {
    super(props)
    this.propagateEvent = this.propagateEvent.bind(this)
  }

  propagateEvent(label, { node, isAppearing, location }) {
    // console.log('PAGE EVENT', label, location, location.state)
    const evt = new Event(transitionEvents[label])
    global.window.dispatchEvent(evt)
    const evtGeneral = new CustomEvent(transitionEvents.page, {
      detail: {
        label,
        node,
        isAppearing,
        location,
      },
    })
    global.window.dispatchEvent(evtGeneral)
  }

  render() {
    const { children, location } = this.props
    return (
      <TransitionGroup className="main-inner">
        <Transition
          key={location.pathname}
          timeout={{
            appear: 0,
            exit: 1000,
            enter: 1000,
          }}
          appear={true}
          onEnter={(node, isAppearing) => {
            this.propagateEvent('enter', {
              node,
              isAppearing,
              location,
            })
          }}
          onEntering={(node, isAppearing) => {
            this.propagateEvent('entering', {
              node,
              isAppearing,
              location,
            })
          }}
          onEntered={(node, isAppearing) => {
            this.propagateEvent('entered', {
              node,
              isAppearing,
              location,
            })
          }}
          onExit={(node) => {
            this.propagateEvent('exit', { node, location })
          }}
          onExiting={(node) => {
            this.propagateEvent('exiting', { node, location })
          }}
          onExited={(node) => {
            this.propagateEvent('exited', { node, location })
          }}
        >
          {(status) => {
            return <>{children}</>
          }}
        </Transition>
      </TransitionGroup>
    )
  }
}
export default PageTransition
