exports.onRouteUpdate = ({ location, prevLocation }) => {
  // pass previous location along for listeners
  if (location && location.state) location.state.prevLocation = prevLocation
}
exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  // pass previous location along for listeners
  if (location && location.state) location.state.prevLocation = prevLocation
}

// exports.shouldUpdateScroll = () => {
//   return false
// }
