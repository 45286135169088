import React from 'react'
import { graphql, Link } from 'gatsby'
// import get from 'lodash/get'
import { Container, Row, Col } from 'react-bootstrap'
// import ArticleCard from '../components/articles/card'
// import CategoryNav from '../components/categories/nav'
// import FeaturedArticle from '../components/articles/feature-card'
import LayoutContext from '../layout/context'
import { transitionEvents } from '../layout/page-transition'
import gsap from 'gsap'

class CategoryPanel extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)
  }

  render() {
    let { category, children, placeholder, className, hideCloseButton } =
      this.props
    category = category || this.context.data.category

    return category ? (
      <div
        id={`${placeholder ? 'placeholder-' : ''}${category.slug}`}
        className={`category-panel ${className || ''} ${
          placeholder ? 'set-back' : ''
        }`}
        style={{
          backgroundColor: `#${category.colorPalette.secondaryColor}`,
        }}
      >
        {!hideCloseButton ? (
          <button
            className="tab-close-button"
            onClick={() => {
              this.context.toggleMap()
            }}
            type="button"
          >
            <span>Close</span>
          </button>
        ) : null}
        <Container fluid="lg" className="py-2 py-md-5">
          <Row>
            <Col className="category-hero-lockup">
              <img src={category.icon.file.url} alt={category.name} />
              <h3 className="category-headline">{category.headline}</h3>
            </Col>
          </Row>
        </Container>
        {children}
      </div>
    ) : null
  }
}

export default CategoryPanel
