import React from 'react'
import _ from 'lodash'

export default (props) => {
  let { number = '!', fill = '#F9D021', stroke = '#000' } = props
  fill = fill.replace(/^([^\#])/, '#$1')
  stroke = stroke.replace(/^([^\#])/, '#$1')
  return (
    <div className="notification-badge" style={{ color: stroke }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 95 100"
      >
        <g>
          <path
            stroke={stroke}
            strokeWidth="8"
            stroke-alignment="inner"
            fill={fill}
            d="M47.5,95.2c-0.8,0-1.6-0.3-2.2-0.8l-6.2-5.1c-2.1-1.7-4.7-2.6-7.3-2.6c-0.2,0-0.5,0-0.7,0
		c-1.4,0.1-8,0.5-8,0.5c-0.1,0-0.1,0-0.2,0c-1.6,0-3-1.1-3.4-2.6l-2-7.8c-0.7-2.8-2.5-5.3-5-6.8l-6.8-4.3c-1.4-0.9-2-2.7-1.4-4.3
		c1-2.6,2.1-5.4,2.9-7.5c1.1-2.7,1.1-5.7,0-8.5l-2.9-7.5c-0.6-1.6,0-3.4,1.4-4.3l6.8-4.3c2.5-1.6,4.2-4,5-6.8l2-7.8
		c0.4-1.6,1.8-2.6,3.4-2.6c0.1,0,0.1,0,0.2,0l8,0.5c0.2,0,0.5,0,0.7,0c2.7,0,5.3-0.9,7.3-2.6l6.2-5.1C45.9,4.3,46.7,4,47.5,4
		c0.8,0,1.6,0.3,2.2,0.8l6.2,5.1c2.1,1.7,4.7,2.6,7.3,2.6c0.2,0,0.5,0,0.7,0l8-0.5c0.1,0,0.1,0,0.2,0c1.6,0,3,1.1,3.4,2.6l2,7.8
		c0.7,2.8,2.5,5.3,5,6.8l6.8,4.3c1.4,0.9,2,2.7,1.4,4.3c-1,2.6-2.1,5.4-2.9,7.5c-1.1,2.7-1.1,5.7,0,8.5c0.5,1.2,2.9,7.5,2.9,7.5
		c0.6,1.6,0,3.4-1.4,4.3l-6.8,4.3c-2.5,1.6-4.2,4-5,6.8l-2,7.8c-0.4,1.6-1.8,2.6-3.4,2.6c-0.1,0-0.1,0-0.2,0l-8-0.5
		c-0.2,0-0.5,0-0.7,0c-2.7,0-5.3,0.9-7.3,2.6l-6.2,5.1C49.1,94.9,48.3,95.2,47.5,95.2z"
          />
        </g>
      </svg>
      <span>{number}</span>
    </div>
  )
}
