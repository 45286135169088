import React from 'react'
import { Link } from 'gatsby'
import Badge from '../misc/badge'
import gsap from 'gsap'
import moment from 'moment'
import mapPinImg from '../../../static/images/map-pin.svg'

class MapPin extends React.Component {
  // export default (props) => {

  constructor(props) {
    super(props)

    let { category } = this.props

    this.state = {
      category,
      hasRecent: false,
    }

    this.bounce = this.bounce.bind(this)
    this.animationRef = React.createRef()
    // this.mouseEnter = this.mouseEnter.bind(this)
    // this.mouseLeave = this.mouseLeave.bind(this)
  }

  componentDidMount() {
    const { mostRecentArticle } = this.state.category
    if (mostRecentArticle) {
      // was this article published in the last 5 days?
      const x = moment().subtract(5, 'days')
      const y = moment(mostRecentArticle.publishDate)
      if (x.isBefore(y)) {
        this.setState({ hasRecent: true })
      }
    }

    // TODO - wait to do this until it scrolls into view
    // window.setTimeout(() => {
    //   this.bounce()
    // }, 500)
  }

  bounce() {
    gsap.fromTo(
      this.animationRef.current,
      {
        y: -20,
        opacity: 0,
      },
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
        ease: 'bounce',
      }
    )
  }

  // mouseEnter() {
  //   gsap.to(this.animationRef.current, {
  //     scale: 1.1,
  //     duration: 0.2,
  //     ease: 'back',
  //   })
  // }

  // mouseLeave() {
  //   gsap.to(this.animationRef.current, {
  //     scale: 1,
  //     duration: 0.2,
  //     ease: 'back',
  //   })
  // }

  render() {
    return (
      <Link
        ref={this.animationRef}
        to={`/categories/${this.state.category.slug}`}
        className="map-pin"
        // style={{ opacity: 0 }}
        // onMouseEnter={this.mouseEnter}
        // onMouseLeave={this.mouseLeave}
      >
        <img src={mapPinImg} alt={this.state.category.name} />
        <span>{this.state.category.name}</span>
        {this.state.hasRecent ? (
          <Badge
            stroke={this.state.category.colorPalette.primaryColor}
            fill={this.state.category.colorPalette.secondaryColor}
          />
        ) : null}
      </Link>
    )
  }
}
export default MapPin
