import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { globalHistory } from '@reach/router'
import get from 'lodash/get'
import ArrowLink from './misc/arrow-link'

/* 
    TODO: 
    - track signup event in GA
*/
class SignupForm extends React.Component {
  constructor(props) {
    super()

    this.state = {
      first_name: '',
      email: '',
      hbcu: '',
      other_hbcu: '',
      showOther: false,
      validated: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name.replace(/(vars\[|\])/gi, '')
    const showOther = name === 'hbcu' ? value === 'Other' : this.state.showOther

    this.setState({
      [name]: value,
      showOther,
    })
  }

  handleSubmit(e) {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }

    this.setState({ validated: true })
  }
  render() {
    return (
      <section className="bg-light">
        <Container fluid="lg">
          <Row className="align-items-center">
            <Col xs="12" md="4" lg="3" className="pb-3 pb-lg-0">
              <h3 className="hu h2 text-black">Sign Up for Updates</h3>
            </Col>
            <Col>
              <Form
                name="signup"
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleSubmit}
                method="POST"
                action="https://cb.sailthru.com/s/6np/subscribers"
              >
                <Form.Control
                  type="hidden"
                  name="redirect"
                  value={`${globalHistory.location.origin}/thank-you`}
                />
                <Row>
                  <Col xs="12" sm="6" lg="4">
                    <Form.Group controlId="name">
                      <Form.Control
                        placeholder="First Name"
                        name="vars[first_name]"
                        required
                        value={this.state.first_name}
                        onChange={this.handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" lg="4">
                    <Form.Group controlId="email">
                      <Form.Control
                        type="email"
                        name="email"
                        required
                        placeholder="Email (ie. joe@example.com)"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" lg="4">
                    <Form.Group controlId="hbcu">
                      <StaticQuery
                        query={graphql`
                          query SignupSchoolsQuery {
                            allContentfulSchool(
                              filter: {
                                id: {
                                  ne: "fc3534f5-12cd-5c9b-a0b3-13680b58dbe6"
                                }
                              }
                              sort: { fields: [name], order: ASC }
                            ) {
                              edges {
                                node {
                                  name
                                  slug
                                }
                              }
                            }
                          }
                        `}
                        render={(data) => {
                          const schools = get(data, 'allContentfulSchool.edges')
                          return (
                            <Form.Control
                              as="select"
                              name="vars[hbcu]"
                              value={this.state.hbcu}
                              onChange={this.handleInputChange}
                            >
                              <option value="" disabled>
                                School affiliation
                              </option>
                              {schools.map(({ node }) => (
                                <option key={node.slug} value={node.name}>
                                  {node.name}
                                </option>
                              ))}
                              <option value="Other">Other...</option>
                            </Form.Control>
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {this.state.showOther ? (
                  <Form.Group controlId="other_hbcu">
                    <Form.Control
                      type="text"
                      name="vars[other_hbcu]"
                      placeholder="Your school affiliation"
                      value={this.state.other_hbcu}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                ) : null}
                <div data-netlify-recaptcha="true"></div>
                <ArrowLink
                  as={Button}
                  klass="text-black"
                  variant="arrow-link"
                  type="submit"
                >
                  Sign Up
                </ArrowLink>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default SignupForm
